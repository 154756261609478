import React from "react";
import "./slider.scss";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between w-screen py-4 gap-8">
      <body>
        <div className="slider">
          <div className="slide-track gap-4">
            {[...Array(40)].map((_, index) => (
              <div className="slide">
                <div className="text-white text-xl font-dot">redacted</div>
              </div>
            ))}
          </div>
        </div>
      </body>

      <div className="font-fyre text-white w-full text-center mt-8">
        <div className="text-3xl sm:text-5xl md:text-5xl">[$redacted]</div>
      </div>

      <div class="flex justify-center items-center">
        <img src="https://imgs.search.brave.com/E9e3Ga6TixxapeRL_YWy2J4TtZVuUZNPqwI0dWNQ7mk/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9iZXN0/YW5pbWF0aW9ucy5j/b20vbWVkaWEvZXll/cy8xNzA3NzcxOTA3/ZXllLWFuaW1hdGlv/bi1naWYtMi5naWY.gif" class="w-full md:w-auto max-w-[400px] h-auto mb-4 md:mb-0" alt="Twitter Logo" />
      </div>

      <div class="flex justify-center items-center mb-8">
        <div class="flex flex-wrap justify-center text-white text-center gap-4 text-xl sm:text-2xl md:text-2xl">
          <a class="font-fyre mb-2 text-center" href="https://twitter.com/redactedcult" target="_blank">
            Twitter
          </a>
          <a class="font-fyre mb-2 text-center" href="https://t.me/redactedcult" target="_blank">
            Telegram
          </a>
          <a class="font-fyre mb-2 text-center" href="https://birdeye.so/token/AaS8spJ7LeSg53Gi5HftqsCm3G2aJohf3RAZVuHPRpZ6?chain=solana" target="_blank">
            Birdeye
          </a>
          <a class="font-fyre mb-2 text-center" href="https://solscan.io/token/AaS8spJ7LeSg53Gi5HftqsCm3G2aJohf3RAZVuHPRpZ6" target="_blank">
            Solscan
          </a>
        </div>
      </div>

      <body>
        <div className="slider">
          <div className="slide-track gap-4">
            {[...Array(40)].map((_, index) => (
              <div className="slide">
                <div className="text-white text-xl font-dot">redacted</div>
              </div>
            ))}
          </div>
        </div>
      </body>
    </div>
  );
};

export default Home;
